import { render, staticRenderFns } from "./BatchViewPage.vue?vue&type=template&id=554e0736&scoped=true&"
import script from "./BatchViewPage.vue?vue&type=script&lang=js&"
export * from "./BatchViewPage.vue?vue&type=script&lang=js&"
import style0 from "./BatchViewPage.vue?vue&type=style&index=0&id=554e0736&scoped=true&lang=css&"
import style1 from "./BatchViewPage.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "554e0736",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('554e0736')) {
      api.createRecord('554e0736', component.options)
    } else {
      api.reload('554e0736', component.options)
    }
    module.hot.accept("./BatchViewPage.vue?vue&type=template&id=554e0736&scoped=true&", function () {
      api.rerender('554e0736', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/annual-review/BatchViewPage.vue"
export default component.exports